import { Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import { CartItem } from './_models/';
import * as fromRoot from './_ngrx/index';
import * as myActions from './_ngrx/actions';
import { AuthenticationService, SolentExpressService } from './_services/';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
    title = 'Solent Wholesale';
    constructor(
        private store: Store<fromRoot.State>,
        private authService: AuthenticationService,
        private expressService: SolentExpressService,
    ) { }

    ngOnInit(): void {
        /* This code will be called on the first load of the page,
        so what we can do here, is query the API for an existing basket
        that the user may have, call it once only and update a basket if it exists
        */

        this.authService.tokenTest().subscribe(
            data => { this.checkBasket(); },
            error => { this.authService.logout(); }
        );
    }

    checkBasket() {
        const customerNo = this.authService.returnCustomerNo();
        if (customerNo !== null) {
            this.expressService.fetchBasket(
                this.authService.returnCustomerNo())
                .subscribe(x => this.expressService.updateBasket(x));
        }
    }

    /*
    updateBasket(basket: CartItem[]) {
        const newBasket = [];
        let lineCount = 0;
        for (const item of basket) {
            // rest line numbers
            const lineNo = lineCount += 1;
            item.lineno = lineNo;
            newBasket.push(item);
        }
        console.log(newBasket);
        this.store.dispatch(myActions.setCartLines ({newLines: lineCount}));
        this.store.dispatch(myActions.updateSavedCart ({cart: newBasket}));
    }
    */
}
