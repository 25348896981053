import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule  } from '@angular/router';
// Third party imports
import { CarouselModule } from 'ngx-owl-carousel-o';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import * as fromExpress from './_ngrx/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// Local imports
import { routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutsModule } from './layouts/layouts.module';
import { BlogModule } from './blog/blog.module';
import { PagesModule } from './pages/pages.module';
import { ProductModule } from './products/product.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { AdminModule } from './admin/admin.module';
import { ExpressModule } from './express/express.module';
import { environment } from '../environments/environment'; // Angular CLI environment
@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent], 
    
    imports: [
        NgbModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AdminModule,
        ProductModule,
        BlogModule,
        PagesModule,
        LayoutsModule,
        ExpressModule,
        CarouselModule,
        SharedModule,
        StoreModule.forRoot({ basketState: fromExpress.reducer }),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        RouterModule.forRoot(routes, { useHash: false, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' })], 
    providers: [provideHttpClient(withInterceptorsFromDi())]
 })
export class AppModule { }
